import { NeutralColors } from '@uifabric/fluent-theme';
import { Card, ICardStyles } from '@uifabric/react-cards';
import moment from "moment";
import { ActionButton, FontWeights, IMessageBarStyles, IStackTokens, ITextStyles, MessageBar, MessageBarType, Spinner, SpinnerSize, Stack, Text } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BackIcon, JoinMeetingIcon, LeaveMeetingIcon, RoomoteIcon } from '../../icons';
import { StartVideoFxMeetingModel, TeamInfo } from '../../models';
import { actions } from '../../store';
import { ApiResult } from "../../store/reducers/ApiResult";
import { getVideoFxRoomClient } from '../../store/selectors';
import { useSelector } from '../../store/utils';
import { DefaultMessageBarStyle } from '../App/MessageBarStyle';
import { VideoFxRoomSchedule } from './VideoFxRoomSchedule';
import css from './VideoFxRoomTap.module.scss';

const cardStyles: ICardStyles = {
  root: {
    padding: 16,
    minWidth: 0,
    maxWidth: 'auto',
    background: NeutralColors.white,
    textAlign: 'center'
  }
}

const titleTextStyles: ITextStyles = {
  root: {
    fontSize: '1rem',
    fontWeight: FontWeights.semibold
  }
}

const roomTextStyles: ITextStyles = {
  root: {
    fontSize: '1rem',
    color: "rgb(0, 120, 212)"

  }
}

// get the maps from https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
// const AppIconProps: Record<string, string> = {
//   "Unknown": "AppIconDefault",
//   "Excel": "ExcelLogo",
//   "Access": "AccessLogo",
//   "Publisher": "PublisherLogo",
//   "OneNote": "OneNoteLogo",
//   "PowerPoint": "PowerPointLogo",
//   "Word": "WordLogo",
// }

const sectionStackTokens: IStackTokens = { childrenGap: 20 };

export const VideoFxRoomTap: React.FC = () => {

  const roomTappedInfo = useSelector(s => s.videofx.roomTapped)
  const videoFxRoomClient = useSelector(s => getVideoFxRoomClient(s, roomTappedInfo?.roomId));
  const myself = useSelector(s => s.auth.user)
  const startVfxMeeting = useSelector(s => s.meetings.startVfxMeeting)
  const leaveVfxMeeting = useSelector(s => s.meetings.leaveVfxMeeting)
  const now = moment()
  const [teamBookingId, setTeamBookingId] = useState<string>()
  const [isBusy, setIsBusy] = useState(false)

  const dispatch = useDispatch()

  const [toastMessage, setToastMessage] = useState<string>()
  const [infoToastMessage, setInfoToastMessage] = useState<string>()

  useEffect(() => {
    if (!videoFxRoomClient && roomTappedInfo) {
      console.log('Initialising videoFxRoomClient')
      dispatch(actions.createRoomApiClient.request(roomTappedInfo.organisationId, roomTappedInfo.roomId));
      console.log('roomTappedInfo', roomTappedInfo)
    }
  }, [dispatch, videoFxRoomClient, roomTappedInfo]);

  const goHome = useCallback(() => {
    dispatch(actions.navigateAppTo('MobileApp', '/'))
  }, [dispatch])

  useEffect(() => {
    if (ApiResult.isSuccess(leaveVfxMeeting)) {
      goHome()
    }
  }, [goHome, leaveVfxMeeting])

  useEffect(() => {
    if (ApiResult.isFailure(leaveVfxMeeting)) {
      setToastMessage(`Leave request failed. ${leaveVfxMeeting.error}`)
      setIsBusy(false)
    }
  }, [leaveVfxMeeting])

  useEffect(() => {
    if (ApiResult.isFailure(startVfxMeeting)) {
      setToastMessage(`Start request failed. ${startVfxMeeting.error}`)
      setIsBusy(false)
    }
  }, [startVfxMeeting])

  const handleDismissToast = useCallback(() => {
    setToastMessage(undefined)
  }, [])

  const handleDismissInfoToast = useCallback(() => {
    setInfoToastMessage(undefined)
  }, [])

  const handleLaunchRoomote = useCallback(() => {
    if (roomTappedInfo) {
      dispatch(actions.navigateAppTo("Roomote", `/index.html?organisationId=${roomTappedInfo.organisationId}&pin=${roomTappedInfo.roomPin}#/`))
    }
  }, [dispatch, roomTappedInfo])

  useEffect(() => {
    if (ApiResult.isSuccess(startVfxMeeting)) {
      handleLaunchRoomote()
    }
  }, [goHome, handleLaunchRoomote, startVfxMeeting])

  useEffect(() => {
    if (roomTappedInfo != null) {
      if (roomTappedInfo.messageTitle || roomTappedInfo.messageBody) {
        // there's a warning message. Do nothing and let the other effect display it.
      } else if (roomTappedInfo.bookingType === 'Teams' && roomTappedInfo.roomState === 'InMeeting') {
        // show add team stuff. Do not redirect to room mote. Let the user choose to do that instead.
      } else {
        // goto roomote if in doubt.
        handleLaunchRoomote()
      }
    }
  }, [handleLaunchRoomote, roomTappedInfo])

  useEffect(() => {
    if (roomTappedInfo == null || !roomTappedInfo.messageTitle || !roomTappedInfo.messageBody) return

    if (roomTappedInfo.messageType === 'Warning' || roomTappedInfo.messageType === 'Error') {
      setToastMessage(roomTappedInfo.messageBody)
    } else {
      setInfoToastMessage(roomTappedInfo.messageBody)
    }
  }, [handleLaunchRoomote, roomTappedInfo])

  const startMeetNowAsUser = useCallback(() => {
    console.log('handleStartAdHocMeeting: roomTappedInfo', roomTappedInfo)

    if (!roomTappedInfo || roomTappedInfo.roomState !== 'Ready' || isBusy) return
    if (!roomTappedInfo.teamsFeatureJoinMeetingAsRoom) return  // no room account to start adhoc meeting

    if (ApiResult.isSuccess(myself)) {
      const body: StartVideoFxMeetingModel = {
        conversation_type: 'StartTeamsAdhocAsUser',
        meet_now_type: 'Teams',
        display_name: (myself.value.givenName + ' ' + myself.value.familyName).trim(),
        sign_in_address: myself.value.emailAddress,
        ad_hoc_duration_minutes: 30,
      }

      console.log('startMeetNow. body:', body);
      setIsBusy(true)
      dispatch(actions.startVfxMeeting.request({ organisationId: roomTappedInfo.organisationId, roomId: roomTappedInfo.roomId, link: "/conversations", body }))
      setInfoToastMessage('An adhoc Teams meeting request has been sent. Please wait...')
      setTimeout(() => { setInfoToastMessage(undefined) }, 5000)
    }
  }, [dispatch, isBusy, myself, roomTappedInfo])

  const startMeetNow = useCallback(() => {
    if (!roomTappedInfo || roomTappedInfo.roomState !== 'Ready' || isBusy) return
    if (!roomTappedInfo.teamsFeatureJoinMeetingAsRoom) return  // no room account to start adhoc meeting

    const body: StartVideoFxMeetingModel = {
      conversation_type: 'MeetNow',
      ad_hoc_duration_minutes: 30,
    }

    if (ApiResult.isSuccess(myself)) {
      body.display_name = (myself.value.givenName + ' ' + myself.value.familyName).trim()
    }

    setIsBusy(true)
    dispatch(actions.startVfxMeeting.request({ organisationId: roomTappedInfo.organisationId, roomId: roomTappedInfo.roomId, link: "/conversations", body }))
  }, [dispatch, isBusy, myself, roomTappedInfo])

  const startMeeting = useCallback((selectedBookingId: string, asUser: boolean, team: TeamInfo | undefined) => {
    console.log('launchMeeting: roomTappedInfo', roomTappedInfo)
    console.log(`launchMeeting called for bookingId ${selectedBookingId} as User ${asUser}`)

    if (!roomTappedInfo || roomTappedInfo.roomState !== 'Ready' || isBusy) return
    if (asUser && !roomTappedInfo.teamsFeatureJoinMeetingAsUser) return
    if (!asUser && !roomTappedInfo.teamsFeatureJoinMeetingAsRoom) return

    if (ApiResult.isSuccess(myself)) {
      const body: StartVideoFxMeetingModel = {
        conversation_type: 'Join',
        booking_id: selectedBookingId,
        display_name: (myself.value.givenName + ' ' + myself.value.familyName).trim(),
      }

      if (asUser) {
        body.sign_in_address = myself.value.emailAddress
      } else if (team !== undefined) {
        body.join_team_id = team.id
        body.join_team_name = team.display_name
      }

      console.log('startMeeting. body:', body);
      setIsBusy(true)
      dispatch(actions.startVfxMeeting.request({ organisationId: roomTappedInfo.organisationId, roomId: roomTappedInfo.roomId, link: "/conversations", body }))
    }
  }, [dispatch, isBusy, myself, roomTappedInfo])

  const handleLeaveMeeting = useCallback(() => {
    if (roomTappedInfo) {
      setIsBusy(true)
      dispatch(actions.leaveMeeting.request({ organisationId: roomTappedInfo.organisationId, roomId: roomTappedInfo.roomId, link: "/room/conversation" }))
    }
  }, [dispatch, roomTappedInfo])

  // const handleStartDesktopApplication = useCallback((link: string) => {
  //   if (roomTappedInfo) {
  //     dispatch(actions.startDesktopApplication.request({ organisationId: roomTappedInfo.organisationId, roomId: roomTappedInfo.roomId, link }))
  //   }
  // }, [dispatch, roomTappedInfo])

  const addTeamToRoom = useCallback((team: TeamInfo) => {
    if (roomTappedInfo) {
      dispatch(actions.addTeamToRoom.request({ organisationId: roomTappedInfo.organisationId, roomId: roomTappedInfo.roomId, team }))
    }
  }, [dispatch, roomTappedInfo])

  if (!roomTappedInfo) {
    return null
  }

  console.log('roomTappedInfo', roomTappedInfo)

  return <main className='app-home'>
    {toastMessage && <MessageBar messageBarType={MessageBarType.warning}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
      className='toastMessageBar'
      onDismiss={() => handleDismissToast()}
      styles={DefaultMessageBarStyle}
    >
      {toastMessage}
    </MessageBar>}

    {infoToastMessage && <MessageBar messageBarType={MessageBarType.info}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
      className='toastMessageBar'
      onDismiss={() => handleDismissInfoToast()}
      styles={DefaultMessageBarStyle}
    >
      {infoToastMessage}
    </MessageBar>}

    <Stack className='app-card-list' tokens={sectionStackTokens}>
      <Card aria-label="VideoFX Room" styles={cardStyles}>
        <Card.Section>
          <Card.Item align="start">
            <Text styles={titleTextStyles}>Welcome to the VideoX Room</Text>
          </Card.Item>
          <Card.Item align="start">
            <Text styles={roomTextStyles}>{roomTappedInfo.roomDisplayName}</Text>
          </Card.Item>
        </Card.Section>

        {/* Initial Start/Stop buttons */}
        <Card.Section>
          <Card.Item align="start">
            <ActionButton className={css.actionButton} onClick={handleLaunchRoomote}><RoomoteIcon />Room Control</ActionButton>
            {!isBusy && roomTappedInfo.roomState === 'Ready' && roomTappedInfo.teamsFeatureJoinMeetingAsRoom && <ActionButton className={css.actionButton} onClick={startMeetNow}><JoinMeetingIcon />Meet Now</ActionButton>}
            {!isBusy && roomTappedInfo.roomState === 'Ready' && roomTappedInfo.teamsFeatureJoinMeetingAsUser && <ActionButton className={css.actionButton} onClick={startMeetNowAsUser}><JoinMeetingIcon />Teams Meet Now as myself</ActionButton>}
            {roomTappedInfo.roomState === 'InMeeting' && <ActionButton className={css.leaveMeeting} onClick={handleLeaveMeeting}><LeaveMeetingIcon />Leave Meeting</ActionButton>}
          </Card.Item>
        </Card.Section>
      </Card>

      {/* 
        My meetings in this room 
        Note that roomId is the room's email, and tagEmail is the current user email. 
        For 'Marks room the roomId is 'MarksRoom'
      */}
      {/* {!isBusy && teamBookingId === undefined && <VideoFxRoomSchedule now={now} roomEmail={roomTappedInfo.tagEmail}
        extra={{
          roomTappedInfo: roomTappedInfo,
          startMeeting: (bookingId) => { startMeeting(bookingId, false, undefined) },
          startMeetingAsUser: (bookingId) => { startMeeting(bookingId, true, undefined) },
          startMeetingWithTeam: (bookingId) => { setTeamBookingId(bookingId) },
          leaveMeeting: handleLeaveMeeting
        }}
      />} */}

      {/* {(roomTappedInfo.officeAppList && roomTappedInfo.officeAppList.length > 0) ?
        <Card aria-label="Application List" styles={cardStyles}>
          <Card.Item align="start">
            <Text styles={titleTextStyles}>Start application using your account</Text>
          </Card.Item>
          <Card.Section>
            {roomTappedInfo.officeAppList.map((item, index) => (<ApplicationActionButton key={index} application={item} />))}
          </Card.Section>
        </Card>
        : null} */}

      {/* {!isBusy && teamBookingId !== undefined && roomTappedInfo.teamsGroupList && roomTappedInfo.teamsGroupList.length > 0 &&
        <Card aria-label="Teams Channel List" styles={cardStyles}>
          <Card.Item align="start">
            <ActionButton onClick={() => setTeamBookingId(undefined)}><BackIcon /></ActionButton>
            <Text styles={titleTextStyles}>Add your Team to the Room</Text>
          </Card.Item>
          <Card.Section>
            {roomTappedInfo.teamsGroupList.map((item, index) => <ActionButton iconProps={{ iconName: "TeamsLogo" }} onClick={() => startMeeting(teamBookingId, false, item)}>{item.display_name}</ActionButton>)}
          </Card.Section>
          <Card.Section>
            <ActionButton onClick={() => setTeamBookingId(undefined)}><BackIcon />Back</ActionButton>
          </Card.Section>
        </Card>} */}

      {roomTappedInfo.roomState === 'InMeeting' && roomTappedInfo.bookingType === 'Teams' && roomTappedInfo.teamsGroupList && roomTappedInfo.teamsGroupList.length > 0 &&
        <Card aria-label="In Meeting Teams Channel List" styles={cardStyles}>
          <Card.Item align="start">
            <Text styles={titleTextStyles}>Add your Team to the current meeting</Text>
          </Card.Item>
          <Card.Section>
            {roomTappedInfo.teamsGroupList.map((item, index) => <ActionButton iconProps={{ iconName: "TeamsLogo" }} onClick={() => {
              console.log('click invoked')
              addTeamToRoom(item)
            }}>{item.display_name}</ActionButton>)}
          </Card.Section>
        </Card>}

      {isBusy && <Spinner size={SpinnerSize.large} />}
    </Stack>
  </main >

  // function ApplicationActionButton(props: { application: ApplicationInfo }) {
  //   return <ActionButton iconProps={{ iconName: AppIconProps[props.application.type] }} onClick={() => handleStartDesktopApplication(props.application.link)}>{props.application.display_name}</ActionButton>
  // }
}