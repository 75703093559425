import moment from 'moment';
import { DefaultButton, Dialog, DialogFooter, DialogType, IconButton, MessageBar, MessageBarType, PrimaryButton, Text } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LaunchAction } from '../../auth/applicationHostingClient';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { NearbyTap, ResourceType } from '../../models';
import { actions } from '../../store';
import { useRefresh } from '../../store/RefreshProvider';
import { useSelector } from '../../store/utils';
import { getFormattedDuration, getResourceTypeDisplayName } from '../../utils/meetingUtils';
import { DefaultMessageBarStyle } from '../App/MessageBarStyle';
import { Schedule } from '../Schedule';
import { ResourceInformation } from '../Schedule/ResourceInformation';
import { ResourcePersona } from '../Schedule/ResourcePersonal';
import css from './Home.module.scss';

interface HomeProps {
}

const modalPropsStyles = { main: { maxWidth: 450 } };

const modalProps = {
  isBlocking: true,
  styles: modalPropsStyles
}

export const Home: React.FC<HomeProps> = (props) => {

  const dispatch = useDispatch()

  const [showLeaveDialog, setShowLeaveDialog] = useState(false)

  const nearbyTap = useSelector(s => s.nearby.tapInfo)

  const myself = useSelector(s => s.auth.user.hasResponse && s.auth.user.value)

  const meetingError = useMemo(() => nearbyTap && nearbyTap.state && nearbyTap.state.isPending ? "The resource has not yet accepted your booking. Please try again soon." : undefined, [nearbyTap])
  const meetingInfo = useMemo(() => nearbyTap && nearbyTap.state && nearbyTap.state.startedState === 'StartedNow' ? `Your booking for ${nearbyTap.name} was started` : undefined, [nearbyTap])

  useEffect(() => {
    setShowLeaveDialog(nearbyTap !== null
      && Boolean(nearbyTap.state)
      && !(nearbyTap.state!.isPending)
      && nearbyTap.state!.startedState === 'StartedEarlier')
  }, [nearbyTap])

  const handleLeaveMeeting = useCallback(() => {
    if (nearbyTap && nearbyTap.state) {
      dispatch(actions.leaveBookItMeeting.request(nearbyTap.state.currentResourceMeetingId))
      setShowLeaveDialog(false)
    }
  }, [dispatch, nearbyTap])

  const allUpcomingMeetings = useSelector(s => s.meetings.upcomingMeetings)
  const startingLaunchAction = useSelector(s => s.hosted.startingLaunchAction)

  useEffect(() => {
    
    // never fetched upcoming meetings so get for user we aren't loading a tag
    if (myself && (allUpcomingMeetings == null || !allUpcomingMeetings.data.isRequested) && !isStopLoadingLaunchAction(startingLaunchAction)) {
      console.log("fetchUpcomingMeetings() dispatched from Home")
      dispatch(actions.fetchUpcomingMeetings.request({email: myself.emailAddress, name: `${myself.givenName} ${myself.familyName}`, resourceType: null}))
    }

  }, [allUpcomingMeetings, dispatch, myself, startingLaunchAction])

  useRefresh(() => dispatch(actions.refreshUpcomingMeetings(false)))

  const now = moment()


  return <main className={css.home}>
    {meetingError && <MessageBar messageBarType={MessageBarType.error}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
      styles={DefaultMessageBarStyle}
    >{meetingError}</MessageBar>}
     {meetingInfo && <MessageBar messageBarType={MessageBarType.info}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
      styles={DefaultMessageBarStyle}
    >{meetingInfo}</MessageBar>}
    {showLeaveDialog && nearbyTap && nearbyTap.state && <Dialog
      hidden={!showLeaveDialog}
      onDismiss={() => setShowLeaveDialog(false)}
      dialogContentProps={{
        type: DialogType.normal,
        title: `Leave ${nearbyTap.name}`,
        subText: getSubText(nearbyTap)
      }}
      modalProps={modalProps}>
      <span className="informationText"></span>
      <DialogFooter>
        <PrimaryButton onClick={() => handleLeaveMeeting()} text="Yes" />
        <DefaultButton onClick={() => setShowLeaveDialog(false)} text="No" />
      </DialogFooter>
    </Dialog>}
    {/* <LocationCode /> */}
    {allUpcomingMeetings && allUpcomingMeetings.context.resourceType !== null 
      && <ResourceHeader name={allUpcomingMeetings.context.name}
                resourceType={allUpcomingMeetings.context.resourceType} 
                informationText={allUpcomingMeetings.context.informationText}
                photoUrl={allUpcomingMeetings.context.photoUrl}
                floorPlanUrl={allUpcomingMeetings.context.floorPlanUrl}
              />}
    <div className={css.cardList}>
      {allUpcomingMeetings && <Schedule now={now.valueOf()} calendarContext={allUpcomingMeetings.context} meetings={allUpcomingMeetings.data}/>}
    </div>
  </main>
}

function getSubText(nearbyTap: NearbyTap): string {
  if (nearbyTap.state === undefined) return ""
  console.log("Due to finish:", nearbyTap)
  const end = nearbyTap.state.isAllDayEvent ? moment().endOf("d"): moment(nearbyTap.state.currentMeetingEnd)
  if (end < moment()) {
    return `This ${getResourceTypeDisplayName(nearbyTap.default_resource!)} finished ${getFormattedDuration(end, moment())} ago. Leave now?`

  }
  return `This ${getResourceTypeDisplayName(nearbyTap.default_resource!)} is due to finish in ${getFormattedDuration(moment(), end)}. Leave now?`
}

function isStopLoadingLaunchAction(launchAction: LaunchAction | null) {
  if (launchAction === null) return false

  if (launchAction[0] === "Nearby") return true

  return false
}

interface ResourceHeaderProps {
  name: string
  resourceType: ResourceType,
  informationText?: string,
  photoUrl?: string,
  floorPlanUrl?: string
}

const infoModalProps = {
  isBlocking: true,
  topOffsetFixed: true,
  styles: modalPropsStyles
}

export const ResourceHeader: React.FC<ResourceHeaderProps> = ({ name, resourceType, informationText, photoUrl, floorPlanUrl }) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  const handleShowInfoDialog = useCallback((show: boolean) => {
    setShowInfoDialog(show)
}, [])
  return <>
   <Dialog
            hidden={!showInfoDialog}
            onDismiss={() => handleShowInfoDialog(false)}
            dialogContentProps={{ type: DialogType.normal, title: `${name} Information` }}
            modalProps={infoModalProps}>
            <ResourceInformation
                informationText={informationText ?? name}
                mapUrl={floorPlanUrl}
                photoUrl={photoUrl}
            />
        </Dialog>
  <div className={css.resourceHeader}>
    <ResourcePersona resourceType={resourceType} className={css.resourceIcon} />
    <Text className={css.resourceName}>{name}</Text>
    {informationText && <IconButton className={css.infoIcon} onClick={() => handleShowInfoDialog(true)}>
      <InfoIcon />
    </IconButton>}
  </div>
  </>
}


