export interface UserAccount {
    givenName: string
    familyName: string
    emailAddress: string
    roles: string[]
}

export interface WhoAmI {
    myself: Contact
    teams: boolean
    singleUser: boolean
    needLogin: boolean
    secondary: SecondaryLogin | null
    staticData: {
       roomSizesFilter: number[]
       canBookAdhoc: boolean
       maxEarlyStartMinutes: number
    },
    organisation: Organisation,
    domain?: string
    device: null | {
       home: HomeType
       name: string
       rooms: Array<{
          emailAddress: string
          directionIcon: string | null
       }>
       floorPlan: string | null
    }
 }

 export interface Organisation {
    rooms: Room[]
    attributes: RoomAttribute[]
    domains: Domains
    onlineMeetingTypes: MeetingType[]
 }
 export type MeetingType = 'SkypeForBusiness' | 'Teams'| 'Jitsi' | 'Zoom' | 'WebEx' | 'GoogleMeet'

 export interface SecondaryLogin {
    contact: Contact
    organisation: Organisation
 }

interface ContactBase {
    name: string;
    emailAddress: string;
}

export const PersonType = 'Person';
export interface Person extends ContactBase {
    type: typeof PersonType;
    jobTitle: string | null;
    department: string | null;
    thumbnailPhoto: string | null;
}

export const RoomType = 'Room';
export interface Room extends ContactBase {
    type: typeof RoomType;
    seats: number;
    attributes: number[];
    location: string;
    hasFloorPlan: boolean;
    roomType: ResourceType
}

export type Contact = Person | Room;

export interface RoomAttribute {
    id: string;
    name: string;
    url: string;
}

export interface MeetingExceptionModel {
    startTime: number;
    endTime: number;
}

export type ResourceType = "MeetingRoom" | "ParkingSpace" | "HotDesk" | "Locker" | "Vehicle" | "Resource" | "Unknown"

export interface ResourceMeeting {
    id?: string
    startOffsetMins: number
    endOffsetMins: number
    emailAddress: string
    name: string
    resourceType: ResourceType
    emailText?: string
    infoUrl?: string
    floorPlanUrl?: string
    photoUrl?: string
    responseStatus?: ResponseStatus
}

export type MeetingExceptions = Record<string, { startTime: number, endTime: number }>

export type MeetingStyle = "Normal" | "AdHoc" | "BookableResource" | "Notification"

export type ResponseStatus = "Unknown" | "NoResponse" | "NoResponseRequired" | "ResponseRequired" | "Accepted" | "Rejected" | "Tentative"


export type CardType = "Meeting" | "MeetingFree" | "MeetingNone"
export type MeetingDataBase = {
    cardType: CardType
    id: string
    calendarContext: string;
    startTime: number
    endTime: number
}
export type MeetingCardType = 
    MeetingFree |
    MeetingData | 
    MeetingNone

export type MeetingFree = MeetingDataBase &  { cardType: 'MeetingFree' }
export function isFreeCard(card: MeetingCardType): card is MeetingFree {
    return card.cardType ===  'MeetingFree';
}

export type MeetingNone = MeetingDataBase & { cardType: 'MeetingNone' }
export function isMeetingNoneCard(card: MeetingCardType): card is MeetingNone {
    return card.cardType ===  'MeetingNone';
}

export type MeetingData = MeetingDataBase & {
    cardType: 'Meeting'
    exchangeId: string;
    subject: string;
    bodyText: string | null;
    organiser?: { name: string, emailAddress: string };
    participants: Array<{ name: string, emailAddress: string, responseStatus: ResponseStatus }>;
    meetingRooms: Array<{ name: string, emailAddress: string, responseStatus: ResponseStatus }>;
    meetingExceptions: MeetingExceptions;
    onlineMeetingTypes: string[];
    isPrivate: boolean;
    isAdHocMeeting: boolean;
    isSafeForUpdate: boolean;
    isAllDayEvent: boolean;
    isStarted: boolean;
    meetingStyle: MeetingStyle
    autoStart: boolean;

    resourceMeetings: null | Array<ResourceMeeting>

    attachments?: Array<{
        uniqueId: string
        name: string
        contentType: string
        size: number
    }>

    selectedAttributes: string[]
}

export function isMeetingCard(card: MeetingCardType): card is MeetingData {
    return card.cardType ===  "Meeting";
}

export interface CalendarContext {
    email: string
    name: string
    resourceType: ResourceType|null
    informationText?: string
    photoUrl?: string
    floorPlanUrl?: string
}

export interface CalendarBatchModel {
    emailAddress: string;
    meetings: MeetingData[]
}

// =========================================================
// Models for interacting with Bookit Schedule endpoint
// =========================================================
export interface period {
    start: number,
    end: number
}

export interface ISchedule {
    day: number,
    periods: Array<period>,
    workingHours: Array<period>
}

export type SchedulesData = Record<string, Array<ISchedule>>

// =========================================================

export interface MeetingException {
    startTime: number
    endTime: number
}

export type Domains = Record<string, string>
export type HomeType = 'RoomList' | 'Timeline' | 'DisplayBoard';


export interface VideoFxMeetingList {
    meetings: Array<VideoFxMeeting>
}

export interface Organiser {
    given_name: string
    family_name: string
    email_address: string
}
export interface VideoFxMeeting {
    booking_id: number
    subject: string
    start: string
    end: string
    organiser: Organiser
    room_display_name?: string
    room_name?: string
}

export interface VideoFxRoomTap {
    roomId: string
    roomPin: string
    tagEmail: string
    
    organisationId: string
    roomState: 'Busy' | 'Ready' | 'JoiningMeeting' | 'InMeeting' | 'LeavingMeeting'
    roomDisplayName: string

    // booking infos are only available when isInMeeting is true
    // the following properties tells you the active meeting's booking info
    bookingId?: string
    bookingType?: 'SkypeForBusiness' | 'Teams' | 'Webex' | 'WebexTeams' | 'Zoom' | 'GoogleMeet' | 'GoToMeeting' | 'RealPresence' | 'Jitsi'
    bookingTitle?: string

    officeAppList: Array<ApplicationInfo>
    teamsGroupList: Array<TeamInfo>
    teamsFeatureJoinMeetingAsRoom: boolean
    teamsFeatureJoinMeetingAsUser: boolean

    messageTitle?: string
    messageBody?: string
    messageType?: 'Information' | 'Warning' | 'Error'
}

export interface ApplicationInfo {
    display_name: string
    link: string
    type: string
}

export interface TeamInfo {
    id: string
    display_name: string
    link: string
}

export interface QuickBookTap {
    name: string
    email: string
    room: string
    type: string
    default_resource?: ResourceType
    no_primary_device: boolean
}

export interface NearbyTap extends QuickBookTap {
    state?: ResourceMeetingState
    resource_floor_plan_url? : string
    resource_photo_url? : string
    resource_information_text? : string    
}

export type ResourceMeetingState = {
    currentResourceMeetingId: string
    currentMeetingStart: number
    currentMeetingEnd: number
    isPending: boolean
    startedState: StartedState
    isAllDayEvent: boolean
}

export type QuickBookBooking = {
    roomEmail: string
    roomName: string
    start: number
    end: number
    isBookable: boolean
} & ({
    isMine: true
    id: string
} | {
    isMine: false
    id: undefined
})

export type StartedState = 'None' | 'StartedNow' | 'StartedEarlier'

export type TagScannedResponseMyBooking = {
    currentMeetingStart: number
    currentMeetingEnd: number
    currentResourceMeetingId: string
    isCurrentlyBookedByMe: true
    startedState: StartedState
    isPending: boolean
    isAllDayEvent: boolean
}

export type TagScannedResponseAvailable = {
    isCurrentlyBookedByMe: false
    startedState: 'None'
}

export type ResourceAvailabilityBlock = {
    start: number
    end: number
    order: number
    name: string
}

export type TagScannedResponse = {
    resourceEmail: string
    resourceName: string
} & (TagScannedResponseAvailable | TagScannedResponseMyBooking)

export interface QuickBookArgs {
    sourceEmail: string
    tagType?: string
    durationMinutes?: number
}

export interface VideoFxRoom {
    name: string;
    display_name: string;
    sip_address?: string;
    room_api_url?: string;
    admin_api_url?: string;
    skype_client_version?: string;
    teams_client_version?: string;
    creation_time?: string;
    is_in_meeting?: boolean;
    team_viewer_id?: string;
    ip_addresses?: string[];
    template_room_name?: string;
    pin?: string;
    provisioning_pin?: string;
    has_license_file: boolean;
    has_event_escalations?: boolean
    next_meeting_start?: string
    _embedded: {
        connection: Connection;
    }
}

export interface StartVideoFxMeetingModel {
    conversation_type: 'StartTeamsAdhocAsUser' | 'MeetNow' | 'Join',
    booking_id?: string,
    invite_list?: string,
    ad_hoc_duration_minutes?: number,
    meet_now_type?: string,
    display_name?: string,
    sign_in_address?: string,
    password?: string,
    bearer_token?: string,
    join_team_id?: string,
    join_team_name?: string
}

export interface Connection {
    room_api: ConnectionInfo[];
}

export interface ConnectionInfo {
    connection_type: ConnectionType;
    url: string;
}

export type ConnectionType = "Direct" | "CloudTunnel" | "OnPremProxy" | "CloudProxy";
export type AppType = "BookIt" | "Roomote" | "MobileApp"
export type NfcTagType = "BookItDevice" | "HotDesk" | "VideoFxRoom" | "QuickBook" | "Nearby"
